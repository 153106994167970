@import '../../../common/scss/variables';

.bg-testimonial {
  background: -webkit-linear-gradient(110deg, #0f61d9 70%, #2470db 50%);
  background: -o-linear-gradient(110deg, #0f61d9 70%, #2470db 50%);
  background: -moz-linear-gradient(110deg, #0f61d9 70%, #2470db 50%);
  background: linear-gradient(110deg, #0f61d9 70%, #2470db 50%);
}
.countdown.ant-statistic > .ant-statistic-content {
  font-size: 16px;
  font-weight: 600;
  color: red;
}

.offer-video {
  .video-height {
    height: 300px;
    @include mobile {
      height: 200px;
    }
  }
}
.letter {
  .left-arrow-top {
    position: absolute;
    top: 12rem;
    @include mobile {
      font-size: 3.5rem;
      top: 10rem;
      right: -15px;
    }
    font-size: 6rem;
    right: -3rem;
    transform: rotate(360deg);
  }

  .left-arrow-bottom {
    position: absolute;
    top: 70rem;
    @include mobile {
      font-size: 3.5rem;
      right: -15px;
    }
    font-size: 6rem;
    right: -3rem;
    transform: rotate(360deg);
  }

  .right-arrow {
    position: absolute;
    top: 40rem;
    @include mobile {
      font-size: 3.5rem;
      left: -18px;
    }
    font-size: 6rem;
    left: -3rem;
    // transform: rotate(360deg);
  }
}

.bg-video {
  background: #ffc000;
  padding: 5rem 0;
}
