$primaryColor: (
  mid: #032558,
  dark: #031f48,
);
$secondaryColor: (
  mid: #ffd200,
  dark: #ffc000,
);
$textColor: (
  light: #fdfdfd,
  mid: #75849b,
  dark: #475b79,
);
$tertiaryColor: (
  dark: #42c169,
  light: #edf5ef,
);
$masterColor: (
  dark: #0f61d9,
  mid: #6298e6,
  light: #abc8f2,
  veryLight: #eaf0ff,
);

//breakpoints
$mobile-width: 576px;
$tablet-width: 768px;
$tabletLdsp-width: 1024px;
$tabletLdsp-height: 768px;
$desktop-width: 1023px;
$xxl: 1440px;

@mixin largeScreen {
  @media (max-width:#{$desktop-width}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width:#{$xxl}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin  maxTablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin mobileAndTablet {
  @include mobile {
    @content;
  }

  @include tablet {
    @content;
  }
}

@mixin tabletLdsp {
  @media screen and (width: #{$tabletLdsp-width}) and (height: #{$tabletLdsp-height}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
