.iframe-main {
    width: 100%;
    height: 65vh;
}

.rightside-scroll{
    &::-webkit-scrollbar-track{
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3); ;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar{
        width: 6px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb{
        background-color: #eeeeee;
    }
}