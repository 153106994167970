@import '../../../common/scss/variables';

.profile-container {
  &__avatar {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    border: 0px;
    margin: 8px 0px;
    background-color: black;
    cursor: pointer;
    width: 95px;
    height: 95px;
    @include desktop {
      width: 100px;
      height: 100px;
    }
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    &-middle {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      height: auto;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
    &-image {
      text-align: center;
      opacity: 1;
      display: block;
      width: 100%;
      transition: 0.5s ease;
      backface-visibility: hidden;
    }
    &:hover &-image {
      opacity: 0.3;
    }
    &:hover &-middle {
      opacity: 1;
      color: map-get($map: $textColor, $key: light);
    }
    &-text {
      font-size: 0.8rem;
    }
  }

  &__username-container {
    display: inline-block;
    background-color: #f1f3f5;
    padding: 1rem 2rem;
    border-radius: 0.3rem;
    margin: 1rem auto;
  }
  &__username-text {
    text-align: center;
    display: inline;
    color: map-get($map: $textColor, $key: mid);
    font-size: 0.7rem;
    @include desktop {
      text-align: left;
      font-size: 0.9rem;
    }
  }
  &__help-text {
    font-size: 0.7rem;
    color: #97a4b8;
    text-align: center;
    @include desktop {
      text-align: left;
      font-size: 0.9rem;
    }
    & span {
      text-decoration: underline;
      color: map-get($map: $masterColor, $key: dark);
    }
  }
}

.payment-method {
  .ant-btn.ant-btn-default {
    background: #0f61d90f !important;
    border: 1px solid #a3b9db !important;
    color: #1561d9 !important;
  }
}

.general-settings {
  &-menu {
    padding: 1.5rem 0.5rem;

    .tabs {
      .ant-tabs-ink-bar {
        display: none;
      }

      .ant-tabs-nav {
        margin: 0;

        &::before {
          border: none;
        }

        .ant-tabs-tab {
          padding: 10px 14px;
          justify-content: start;

          .ant-tabs-tab-btn {
            color: #031f48;
          }

          &.ant-tabs-tab-active {
            background-color: rgba(140, 140, 140, 0.1);
            color: #031f48;
            border-radius: 0.375rem;

            .ant-tabs-tab-btn {
              color: #031f48;
            }
          }
        }
      }

      .ant-tabs-content-holder {
        border: none;
        display: none;
      }
    }
  }

  &-content {
    padding: 1.5rem 0.5rem;
    min-height: 60vh;
  }

  &-tabs {
    height: 100%;
    overflow: unset;

    .ant-tabs-nav {
      margin-bottom: 2rem;
      .ant-tabs-tab {
        padding: 10px 15px;

        .ant-tabs-tab-btn {
          display: flex;
          align-items: center;

          ion-icon {
            margin-right: 8px;
          }
        }
      }
    }
    .ant-tabs-content-holder {
      .image-preview-box {
        height: 150px;
        width: 100%;
        margin-top: 1rem;
        border-radius: 5px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      div[id='font-picker-pageFont'] {
        .dropdown-button {
          border-radius: 0.375rem !important;
        }

        /* &.expanded {
          .font-list {
            max-height: 150px;
          }
        } */

        .font-list {
          background-color: #ffffff !important;
          border-radius: 0.375rem !important;
          margin-top: 2px;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2) !important;

          .font-list-item {
            .font-button {
              &:hover {
                background-color: rgba(237, 242, 247, var(--bg-opacity));
              }
            }
          }
        }
      }
    }

    .font-settings {
      &-preview {
        margin: 2rem 0;
      }
    }

    .analytics {
      .ant-input {
        --text-opacity: 1;
        color: #718096;
        color: rgba(113, 128, 150, var(--text-opacity));
        font-size: 0.75rem;
        padding: 1rem;
      }
    }
  }
}
.payment-gateway, .booking-gateway {
  .ant-input-group-addon {
    color: #0f61d9;
    letter-spacing: 0.3px;
    background-color: #0f60d911;
  }
}

//mobile 300px
.ant-notification-notice.ant-notification-notice-closable {
  width: auto;
  @include mobile {
    width: 300px !important;
  }
}

.int-notification {
  .description {
    font-size: 14px;
    margin-right: 1.5rem;
  }
  .ant-notification-hook-holder,
  .ant-notification-notice {
    width: auto;
  }
  .hash {
    width: 100%;
    padding: 4px 4px 4px 10px;
    border-radius: 4px;
    border: 1px solid #e8ebef;
    margin-right: 20px;

    .ant-input {
      color: #031f48;
      font-weight: 700;
      opacity: 0.65;
      padding-right: 2rem;
    }

    .copy-icon {
      background-color: rgba(15, 97, 217, 0.2);
      border-color: rgba(15, 97, 217, 0.2);
      border-radius: 5px;

      &:hover {
        background-color: rgba(15, 97, 217, 0.3);
        border-color: rgba(15, 97, 217, 0.3);
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media screen and (max-width: 1024px) {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }

    @media screen and (max-width: 1024px) {
      min-width: 100%;
    }
  }
}

.account-profile {
  &-button {
    .ant-btn {
      height: 40px;
      color: #2c84ff;
      background-color: #f6f8fb;
      border: none;
      padding: 4px 16px;
    }
  }
}

.billing-card-container {
  height: 215px;
  border-radius: 4px;
  padding: 1.3rem;
}

.payment-card {
  .ant-btn {
    color: #2c84ff;
    background-color: #f6f8fb;
    border: 1px solid #d5e6ff;
  }
}

.cleave-input-icon {
  position: absolute;
  right: 0.5rem;
  top: 22%;
  &-mastercard {
    background-image: url('../../../../assets/images/mastercard.svg');
    height: 30px;
    width: 23px;
    background-repeat: no-repeat;
  }
  &-visa {
    background-image: url('../../../../assets/images/visa.svg');
    height: 30px;
    width: 23px;
    background-repeat: no-repeat;
  }
  &-unknown {
    background-image: url('../../../../assets/images/defaultCard.svg');
    height: 30px;
    width: 23px;
    background-repeat: no-repeat;
  }
  &-verve {
    background-image: url('../../../../assets/images/verve.svg');
    height: 30px;
    width: 23px;
    background-repeat: no-repeat;
  }
}

.cancel-modal {
  .-top-45 {
    top: -45px;
  }
  .ant-btn.btn {
    border-radius: 8px;
    border: unset;
    box-shadow: none;
    font-size: 14px;
    font-weight: bold;
    text-shadow: none;
    &.cancel-btn {
      background-color: #e7f0fc;
      color: #0f61d9;
    }
    &.danger-btn {
      background-color: #c7483e;
      color: white;
    }
  }
}

.preference-checkbox.ant-checkbox-wrapper {
  margin-left: 0;
  font-size: 16px;
}
