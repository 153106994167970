@import '../../../common/scss/variables';

.affiliate-v2 {
	border-radius: 8px;
	background: map-get($map: $textColor, $key: light);

	@include mobile {
		padding-bottom: 4rem;
	}

	.ant-tabs-tab {
		padding: 12px 0;
	}

	&__title {
		font-size: 1.5rem;
		font-weight: bold;
		margin-bottom: 2rem;
		color:map-get($map: $primaryColor, $key: dark);
		@include mobile {
			display: none;
		}
	}

	&-statistics {
		margin-bottom: 2.5rem;
		.ant-col {
			&:first-of-type {
				.card {
					background-color: #1561d9;

					.ant-card-actions {
						li {
							color: #fdfdfd;

							span {
								color: #fdfdfd;
								opacity: 1;
							}

							.ant-btn-primary {
								background-color: #ffffff;
								border-color: #fdfdfd;

								span {
									color: #1561d9;
								}
							}
						}
					}

					.ant-card-head {
						.ant-card-head-title,
						.ant-card-extra {
							color: #fdfdfd;
						}
					}

					.ant-card-body {
						.ant-typography {
							color: #ffffff;
						}
					}
				}
			}
		}

		.card {
			border-radius: 5px;
			background-color: #ffffff;

			.ant-card-head {
				border: none;
				padding: 0 15px;

				.ant-card-head-title {
					font-size: 0.8rem;
					color: #031f48;
					opacity: 0.75;
				}
			}

			.ant-card-actions {
				float: none;
				border: none;
				padding: 0 15px;
				background-color: transparent !important;
				display: flex;
				justify-content: space-between;
				align-items: center;

				li {
					color: #031f48;
					text-align: left;

					&:not(:last-child) {
						border: none;
						opacity: 0.75;
					}

					&:not(:first-child) {
						text-align: right;
					}

					span {
						cursor: inherit;
						font-size: 0.8rem;
						opacity: 0.75;
						color: #031f48;
					}
				}
			}

			.ant-card-body {
				padding: 0 15px 0;

				.ant-typography {
					margin: 0;
					color: #031f48;
				}
			}
		}
	}

	&-link {
		margin: 0 0 2.5rem;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.link-title {
			font-size: 1.1rem;
			color: #031f48;
			margin-right: 1.5rem;
		}

		.url {
			width: auto;
			min-width: 450px;
			padding: 4px 4px 4px 10px;
			border-radius: 4px;
			border: 1px solid #e8ebef;
			margin-right: 20px;

			.ant-input {
				color: #031f48;
				font-weight: 700;
				opacity: 0.65;
				padding-right: 2rem;
			}

			.copy-icon {
				background-color: rgba(15, 97, 217, 0.2);
				border-color: rgba(15, 97, 217, 0.2);
				border-radius: 5px;

				&:hover {
					background-color: rgba(15, 97, 217, 0.3);
					border-color: rgba(15, 97, 217, 0.3);
				}
			}

			&:last-of-type {
				margin-right: 0;
			}

			@media screen and (max-width: 1024px) {
				margin-right: 0;
				margin-bottom: 0.5rem;
			}

			@media screen and (max-width: 1024px) {
				min-width: 100%;
			}
		}
	}

	&-tabs {
		.ant-tabs-nav {
			@media screen and (max-width: 1024px) {
				flex-wrap: wrap-reverse;
			}
		}

		.ant-tabs-extra-content {
			@media screen and (max-width: 767px) {
				width: 100%;
			}
			.ant-space {
				margin-bottom: 0.5rem;

				@media screen and (max-width: 767px) {
					flex-wrap: wrap;
				}

				.ant-space-item {
					.ant-input-affix-wrapper,
					.ant-select-selector {
						border-color: #e5e8ec;

						&:hover,
						&:focus {
							border-color: #40a9ff;
						}
					}

					.ant-input-affix-wrapper-focused {
						border-color: #40a9ff;
					}

					@media screen and (max-width: 767px) {
						margin-bottom: 0.5rem;
						width: 100%;
					}

					&:last-of-type {
						@media screen and (max-width: 767px) {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	&-table {
		.ant-table-thead {
			background-color: #ffffff;
			& > tr > th {
				background-color: #ffffff;
				color: rgba(25, 40, 60, 0.85);
				font-size: 0.95rem;
				font-weight: 700;
			}
		}

		.ant-table-tbody {
			color: rgba(25, 40, 60, 0.65);

			.ant-tag {
				border-radius: 4px;

				&.ant-tag-green {
					background-color: #42c1690f;
					border-color: #42c169;
					color: #42c169;
				}

				&.ant-tag-blue {
					background-color: #3a5aa10f;
					border-color: #3a5aa1;
					color: #3a5aa1;
				}

				&.ant-tag-red {
					background-color: #d94d3a0f;
					border-color: #d94d3a;
					color: #d94d3a;
				}
			}
		}
	}
}

.default-title {
	font-size: 1.1rem;
	color: #031f48 !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
