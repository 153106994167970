@import '../../../common/scss/variables';

.leads {
    .ant-tabs-nav-list {
        padding-left: 10px;
        @include tablet {
            padding-left: 15px;
        }
        @include desktop {
            padding-left: 40px;
        }
    }
    .pads-horizontal {
        padding: 0 10px;
        @include tablet {
            padding: 0 15px;
        }
        @include desktop {
            padding: 0 40px;
        }    
    }
}

.view-details {
  &-content {
    padding: 1.5rem 1.3rem 1.8rem;
  }

  &-scrollbar{
      &::-webkit-scrollbar-track{
          background-color: #F5F5F5;
          border-radius: 8px;
      }
  
      &::-webkit-scrollbar{
          border-radius: 10px;
          width: 16px;
          background-color: #F5F5F5;
      }
  
      &::-webkit-scrollbar-thumb{
        background-color: #dbdbdb;
        border: 4px solid transparent;
        border-radius: 8px;
        background-clip: padding-box;  
      }
  }

  &-padding {
      padding: 1.5rem 2.5rem;
      @include mobile{
        padding: 1.5rem;
      }
  }

  &-menu {
    padding: 1.5rem 1.3rem;
    .tabs {
      .ant-tabs-ink-bar {
        display: none;
      }
      
      .ant-tabs-nav {
        width: 100%;
        margin: 0;

        &::before {
          border: none;
        }

        .ant-tabs-tab {
          padding: 10px 14px;
          justify-content: start;
          color: rgba(255, 255, 255, 0.6);

          .ant-tabs-tab-btn {
            color: rgba(255, 255, 255, 0.6);
          }

          &.ant-tabs-tab-active {
            background-color: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 1);
            border-radius: 0.375rem;

            .ant-tabs-tab-btn {
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }

      .ant-tabs-content-holder {
        border: none;
        display: none;
      }
    }
  }
}

  
.create-listAndTags{
  .ant-select-selector{
    .ant-select-selection-placeholder{
      font-size: 14.5px;
      margin-left: 4px;
    }
  }
}

.leadsContentContainer{
  .ant-btn.ant-dropdown-trigger.dropdown-button{
    font-size: 0.95rem;
    padding: 0.375rem 1.5rem;
    height: 37px;
  }
}

.leads-table{
  .ant-tag.leads-table-tags{
    background: #6299e61e;
    line-height: 24px;
    color: #0f61d9;
    padding: 1px 8px;
    .ant-tag-close-icon{
      color:red
    }
  }
}