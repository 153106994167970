@import '../scss/variables';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.page-loader {
  border: 2px solid map-get($map: $masterColor, $key: veryLight);
  border-top: 2px solid map-get($map: $primaryColor, $key: dark);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.6s linear infinite;

  &__wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.leftSidebar {
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  padding: 1rem 0.75rem 2.5rem;
  min-height: 100vh;
  @include mobile {
    position: fixed;
    overflow-y: auto;
    height: 100vh;
    max-width: 17rem;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    &.active {
      transform: translateX(0);
    }
  }
}

.device-dot {
  display: inline-block;
  position: absolute;
  top: 7px;
  right: 13px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #ffc000;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: map-get($map: $primaryColor, $key: dark);
}

input:focus + .slider {
  box-shadow: 0 0 1px #000;
}

input:checked + .slider-light {
  background-color: #5077dc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.rightSidebar {
  height: 100vh;
  background-color: map-get($map: $textColor, $key: light);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  transform: translateX(100%);
  max-width: 50rem;
  @include mobile {
    transform: translateY(100%);
  }
  transition: transform 0.3s ease-out;
  box-shadow: 0px 3px 16px #0000000f;
  -webkit-box-shadow: 0px 3px 16px #0000000f;
  -moz-box-shadow: 0px 3px 16px #0000000f;

  &.active {
    transform: translateX(0);
  }
  @include tablet {
    max-width: 19rem;
  }
  @include desktop {
    max-width: 20rem;
  }
  z-index: 200;
}

.sb-component-edit {
  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-nav-list {
    padding: 0 8px;
    width: 100%;
    justify-content: space-between;

    .ant-tabs-tab {
      margin: 0;
      flex-grow: 1;
      justify-content: center;
    }
  }

  .ant-tabs-content-holder {
    overflow-y: scroll;
    padding-top: 10px;
  }
}

.image-uploader-btn {
  display: block;
  width: 100%;

  .ant-upload {
    width: 100%;
  }

  .ant-btn {
    background-color: #ffffff;
    opacity: 1;
    border-color: #0e50af;
    color: #0e50af;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-color: #eaf0ff;
    }
  }
}

.embed-iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  .embed-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.create-tag-custom {
  &__divider {
    margin: 4px 0;
  }
  &__container {
    display: flex;
    flex-wrap: nowrap;
    padding: 8px;
  }
  &__input {
    flex: auto;
  }
  &__cta {
    cursor: pointer;
    display: block;
    flex: none;
    padding: 8px;
  }
}

.subscription-v3 {
  display: flex;
  flex-direction: column;
  background-color: #f3f4f8;
  &.full-height {
    height: 100%;
  }
  &.subscription-v3-plain {
    background-color: white;
  }
  padding: 2rem 15px 3rem;
  @include mobile {
    padding-top: 40px;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    margin-bottom: 2rem;
    &.title-bottom-1 {
      margin-bottom: 1rem;
    }
    @include mobile {
      font-size: 2rem;
    }
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    color: #031f48;
  }

  .ant-select.currency-select-dropdown {
    font-size: 1.1rem;
    font-weight: 600;
    .ant-select-selector {
      height: 35px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
      border-radius: 22px;
      border: none;
      padding: 4px 18px 4px 11px;
    }
    .ant-select-arrow {
      top: 33%;
      right: 20px;
    }
  }
  .ant-btn.subscribe-btn.ant-btn-lg {
    height: 48px;
    border-radius: 8px;
    font-weight: 600;
    &.btn-yellow {
      background-color: #ffc000;
      border-color: #ffc000;
    }
  }
  .-top-6 {
    top: -6px;
  }
  .-top-30 {
    top: -30px;
  }
  .-top-18 {
    top: -18px;
  }
  .ant-btn.ant-btn-primary.pay-btn {
    background-color: #42c169;
    height: 64px;
    padding: 16px 20px;
    width: 360px;
    text-align: left;
    @include mobile {
      width: 100%;
    }
    border-radius: 16px;
    font-size: 16px;
    border-color: #42c169;
    display: inherit;
  }
}

.auth-modal {
  .-top-45 {
    top: -45px;
  }
}
.register-form {
  .ant-select-arrow {
    top: 50%;
  }
  .ant-input {
    padding: 8px 11px;
  }
}

.stripe-modal {
  .-top-45 {
    top: -45px;
  }
  .ant-btn.stripe-pay-btn {
    background: #635bff;
    border-color: #635bff;
    height: 40px;
    border-radius: 6px;
    &:hover {
      background: #635bffdf;
    }
  }

  input,
  .StripeElement {
    display: block;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    border: 1px solid #e2e8f0a6;
    border-radius: 6px;
    background: white;
  }

  input:focus,
  .StripeElement--focus {
    border: 1px solid #3e3d4d38;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .ant-skeleton {
    .ant-skeleton-content {
      .ant-skeleton-title {
        width: 100% !important;
      }
    }
  }

  .align-icon {
    top: 28px;
    margin-right: 10px;
  }
}

.ant-picker.softlink-date-range-picker {
  border: none;
  z-index: -3;
  position: absolute;
  display: none;

  .ant-picker-input {
      display: none;
  }
  &.ant-picker-focused {
      box-shadow: none;
  }
  .ant-picker-active-bar {
      display: none;
  }
}

.softlink-sidebar.ant-menu{
  background: transparent;
  border-right: unset;
}