.gpw {
  padding: 0;

  .trigger {
    padding: 0.5rem 1rem !important;
    background: transparent !important;
    border-radius: 4px !important;
    box-shadow: 0 1px 0px -1px rgba(0, 0, 0, 0.03), 0 2px 3px -1px rgba(0, 0, 0, 0.06) !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid #e2e8f0;

    .inner {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }

    &::before {
      content: 'Gradient colors';
      font-size: 80%;
      color: #475b79;
    }
  }

  .popover {
    z-index: 2;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px, rgba(0, 0, 0, 0.15) 0 8px 16px;
    padding: 12px;
    border-radius: 4px;
    position: absolute;
    background-color: #fff;

    .angle-holder {
      margin: 0 -10px;
      padding: 10px 0 0 10px;
      border-top: 1px solid rgb(238, 238, 238);
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
    }

    .angle-inputs {
      border-radius: 4px;
      background: #f2f2f2;
      display: flex;
      flex: 1;
      margin: 0 20px;
      justify-content: space-around;
      align-items: center;

      input {
        border: none;
        text-align: center;
        width: 48px;
        color: #0c0c09;
        background: inherit;
      }

      span {
        padding: 5px;
        cursor: pointer;
        user-select: none;
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.close-gradient-button {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
  display: flex;
  position: absolute;
  left: 0px;
  transform: translate3d(90px, 0px, 0px);
}