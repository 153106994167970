@import './variables';

// TODO: Compartmentalize the styles so they are only loaded when the components requiring them are active.
@import '../components/styles.scss';
@import './builderQuill.scss';
@import './standaloneQuill.scss';
@import './emailQuill.scss';
@import '../../pages/affiliate/components/styles.scss';
@import '../../pages/pageBuilder/advanced/components/styles.scss';
@import '../../pages/settings/components/styles.scss';
@import '../../pages/subscription/components/styles.scss';
@import '../../pages/pageBuilder/simple/components/componentsEdit/styles.scss';
@import '../../pages/leads/components/styles.scss';

.ant-switch-checked {
  background-color: #42c169 !important;
}

  button {
    &:focus {
      outline: none !important;
    }
  }

  html{
    scroll-behavior: smooth;
  }