
div#error {
  position: relative;
  margin: 0 auto;
  padding: 20px;
  z-index: 2;
}
div#error div#box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #031f48;
}
div#error div#box:before,
div#error div#box:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 0px 0px 1px #031f48;
  mix-blend-mode: multiply;
  animation: dance 4s infinite steps(1);
}
div#error div#box:before {
  clip-path: polygon(0 0, 65% 0, 35% 100%, 0 100%);
  box-shadow: inset 0px 0px 0px 1px currentColor;
  color: #0F61D9;
}
div#error div#box:after {
  clip-path: polygon(65% 0, 100% 0, 100% 100%, 35% 100%);
  animation-duration: 0.5s;
  animation-direction: alternate;
  box-shadow: inset 0px 0px 0px 1px currentColor;
  color: #EFF1F4;
}
div#error h3 {
  position: relative;
  font-size: 5vw;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  animation: blink 1.3s infinite steps(1);
}
div#error h3:before,
div#error h3:after {
  content: 'ERROR 500';
  position: absolute;
  top: -1px;
  left: 0;
  mix-blend-mode: soft-light;
  animation: dance 4s infinite steps(2);
}
div#error h3:before {
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
  color: #0F61D9;
  animation: shiftright 4s steps(2) infinite;
}
div#error h3:after {
  clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 50%);
  color: #EFF1F4;
  animation: shiftleft 4s steps(2) infinite;
}
div#error p {
  position: relative;
  margin-bottom: 8px;
  font-size: 1rem;
}
div#error p span {
  position: relative;
  display: inline-block;
  font-weight: bold;
  color: #031f48;
  animation: blink 3s steps(1) infinite;
}
div#error p span:before,
div#error p span:after {
  content: 'unstable';
  position: absolute;
  top: -1px;
  left: 0;
  mix-blend-mode: multiply;
}
div#error p span:before {
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
  color: #0F61D9;
  animation: shiftright 1.5s steps(2) infinite;
}
div#error p span:after {
  clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 50%);
  color: #EFF1F4;
  animation: shiftleft 1.7s steps(2) infinite;
}
@-moz-keyframes dance {
  0%, 84%, 94% {
    transform: skew(0deg);
  }
  85% {
    transform: skew(5deg);
  }
  90% {
    transform: skew(-5deg);
  }
  98% {
    transform: skew(3deg);
  }
}
@-webkit-keyframes dance {
  0%, 84%, 94% {
    transform: skew(0deg);
  }
  85% {
    transform: skew(5deg);
  }
  90% {
    transform: skew(-5deg);
  }
  98% {
    transform: skew(3deg);
  }
}
@-o-keyframes dance {
  0%, 84%, 94% {
    transform: skew(0deg);
  }
  85% {
    transform: skew(5deg);
  }
  90% {
    transform: skew(-5deg);
  }
  98% {
    transform: skew(3deg);
  }
}
@keyframes dance {
  0%, 84%, 94% {
    transform: skew(0deg);
  }
  85% {
    transform: skew(5deg);
  }
  90% {
    transform: skew(-5deg);
  }
  98% {
    transform: skew(3deg);
  }
}
@-moz-keyframes shiftleft {
  0%, 87%, 100% {
    transform: translate(0, 0) skew(0deg);
  }
  84%, 90% {
    transform: translate(-8px, 0) skew(20deg);
  }
}
@-webkit-keyframes shiftleft {
  0%, 87%, 100% {
    transform: translate(0, 0) skew(0deg);
  }
  84%, 90% {
    transform: translate(-8px, 0) skew(20deg);
  }
}
@-o-keyframes shiftleft {
  0%, 87%, 100% {
    transform: translate(0, 0) skew(0deg);
  }
  84%, 90% {
    transform: translate(-8px, 0) skew(20deg);
  }
}
@keyframes shiftleft {
  0%, 87%, 100% {
    transform: translate(0, 0) skew(0deg);
  }
  84%, 90% {
    transform: translate(-8px, 0) skew(20deg);
  }
}
@-moz-keyframes shiftright {
  0%, 87%, 100% {
    transform: translate(0, 0) skew(0deg);
  }
  84%, 90% {
    transform: translate(8px, 0) skew(20deg);
  }
}
@-webkit-keyframes shiftright {
  0%, 87%, 100% {
    transform: translate(0, 0) skew(0deg);
  }
  84%, 90% {
    transform: translate(8px, 0) skew(20deg);
  }
}
@-o-keyframes shiftright {
  0%, 87%, 100% {
    transform: translate(0, 0) skew(0deg);
  }
  84%, 90% {
    transform: translate(8px, 0) skew(20deg);
  }
}
@keyframes shiftright {
  0%, 87%, 100% {
    transform: translate(0, 0) skew(0deg);
  }
  84%, 90% {
    transform: translate(8px, 0) skew(20deg);
  }
}
@-moz-keyframes blink {
  0%, 50%, 85%, 100% {
    color: #031f48;
  }
  87%, 95% {
    color: transparent;
  }
}
@-webkit-keyframes blink {
  0%, 50%, 85%, 100% {
    color: #031f48;
  }
  87%, 95% {
    color: transparent;
  }
}
@-o-keyframes blink {
  0%, 50%, 85%, 100% {
    color: #031f48;
  }
  87%, 95% {
    color: transparent;
  }
}
@keyframes blink {
  0%, 50%, 85%, 100% {
    color: #031f48;
  }
  87%, 95% {
    color: transparent;
  }
}
