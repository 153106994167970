@import '../../../common/scss/variables';

.subscription {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding: 2rem 15px 3rem;
  @include mobile {
    padding-top: 40px;
  }

  .title {
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    margin-bottom: 1rem;
    @include mobile {
      font-size: 2rem;
    }
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    color: #031f48;
  }

  .description {
    font-size: 1.1rem;
    color: rgba(3, 31, 72, 1);
    text-align: center;
  }

  &-box {
    margin-top: 2rem;

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        .ant-tabs-tab {
          &.ant-tabs-tab-active {
            background-color: rgba(15, 97, 217, 0.1);
            color: #0f61d9;
          }
        }
      }
    }

    .plan {
      background-color: #ffffff;
      padding: 1.3rem;
      border-radius: 8px;
      box-shadow: 0px 3px 16px #00000005;
      height: 100%;
      color: #031f48;

      &-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 1.3rem;

        img {
          min-height: 74px;
        }
      }

      &-title {
        font-size: 1.4rem;
        font-weight: 700;
        margin: 0;
        color: #0f61d9;
      }

      &-description {
        margin: 0;
      }

      &-pricing {
        font-size: 3rem;

        .price {
          color: #031f48;
        }

        sup,
        sub {
          font-size: 30%;
          color: #031f48;
          opacity: 0.65;
        }

        sup {
          top: -2em;
        }

        sub {
          bottom: 0;
        }
      }

      &-discount {
        font-size: 0.9rem;
        color: rgba(3, 31, 72, 0.65);
        margin-bottom: 2rem;
      }

      &-button {
        font-size: 1.1rem;
        height: auto;
        border-radius: 6px;
        font-weight: 500;
        padding: 15px 15px;
        background-color: #0f61d9;
        border-color: #0f61d9;

        &:hover {
          background-color: darken(#0f61d9, 5);
          border-color: darken(#0f61d9, 5);
        }
      }

      &-features {
        margin-top: 2rem;
        color: #031f48;

        & h4.ant-typography {
          font-size: 1rem;
          color: #031f48;
          margin-bottom: 1.3rem;
        }

        .list {
          color: rgba(3, 31, 72, 0.65);

          li {
            margin-bottom: 0.7rem;
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;

            &.coming-soon {
              .ant-tag {
                color: #0f61d9;
                border-radius: 10px;
                margin-left: 10px;
                font-size: 10px;
              }
            }

            .anticon {
              margin-right: 10px;
              font-size: 1rem;
              color: #0f61d9;
              text-shadow: 0 -1px 0 rgba(3, 31, 72, 0.65);
            }
          }
        }
      }
    }

    .ant-tabs-nav {
      &::before {
        content: none !important;
      }

      .ant-tabs-nav-wrap {
        justify-content: center;

        .ant-tabs-nav-list {
          border: 1px solid #031f481f;
          border-radius: 8px;
          padding: 2px;
        }

        .ant-tabs-tab {
          padding: 5px 15px;
          border-radius: 8px;
          min-width: 120px;
          justify-content: center;
          margin: 0;
          color: #031f48;
          font-size: 1rem;
          font-weight: 500;
        }

        .ant-tabs-ink-bar {
          background: transparent;
        }
      }
    }

    .ant-tabs {
      height: 100%;
    }

    .ant-col {
      &:first-of-type {
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            .ant-tabs-tab {
              &.ant-tabs-tab-active {
                background-color: rgba(66, 193, 105, 0.1);
                color: #42c169;

                .ant-tabs-tab-btn {
                  color: #42c169;
                }
              }
            }
          }
        }

        .plan-title {
          color: #42c169;
        }

        .plan-button {
          background-color: #42c169;
          border-color: #42c169;

          &:hover {
            background-color: darken(#42c169, 5);
            border-color: darken(#42c169, 5);
          }
        }

        .plan-features {
          .list {
            li {
              .anticon {
                color: #42c169;
              }
            }
          }
        }
      }

      &:last-of-type {
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            .ant-tabs-tab {
              &.ant-tabs-tab-active {
                background-color: rgba(255, 192, 0, 0.2);
                color: rgba(3, 31, 72, 1);

                .ant-tabs-tab-btn {
                  color: rgba(3, 31, 72, 1);
                }
              }
            }
          }
        }

        .plan-title {
          color: #ffc000;
        }

        .plan-button {
          background-color: #ffc000;
          border-color: #ffc000;
          color: #000000;

          &:hover {
            background-color: darken(#ffc000, 5);
            border-color: darken(#ffc000, 5);
          }
        }

        .plan-features {
          .list {
            li {
              .anticon {
                color: #ffc000;
              }
            }
          }
        }
      }
    }
  }

  &__option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 32px auto;

    & .switch {
      margin: 0px 16px;

      input:checked + .slider {
        background-color: #031f48;
      }
    }

    &-text {
      font-style: normal;
      font-size: 1rem;
      color: rgba(0, 21, 75, 0.5);
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1px;
      margin-bottom: 0;

      &.active {
        color: rgba(0, 21, 75, 1);
      }
    }
  }
}
