@import './quill.scss';

.standalone-quill {
  //Style edits
  .ql-toolbar.ql-snow {
    border: 1px solid #031f4811;
    padding: 8px 3px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .ql-container.ql-snow {
    border: none;
    border-radius: 0px 0px 5px 5px;
    height: 11rem;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    height: 150px;
    overflow: auto;
  }

  .toolbar-switch.ant-switch-small {
    height: 17px !important;
  }

  .ql-color .ql-picker-options [data-value='color-picker']:before {
    content: 'Pick Color';
  }

  .ql-background .ql-picker-options [data-value='background-picker']:before {
    content: 'Pick Color';
  }

  .ql-color .ql-picker-options [data-value='color-picker'] {
    background: white !important;
    width: 100% !important;
    height: 25px !important;
    text-align: center;
    color: #353535;
    border: 1px #eaeaea solid;
    border-radius: 3px;
    margin-top: 7px;
    margin-left: 0px;
    outline: none;
    &:hover {
      border: 1px #d4d4d4 solid;
      color: #292929;
    }
  }

  .ql-background .ql-picker-options [data-value='background-picker'] {
    background: white !important;
    width: 100% !important;
    height: 25px !important;
    text-align: center;
    color: #353535;
    border: 1px #eaeaea solid;
    border-radius: 3px;
    margin-top: 7px;
    margin-left: 0px;
    outline: none;
    &:hover {
      border: 1px #d4d4d4 solid;
      color: #292929;
    }
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    width: unset;
    margin: 8px 0;
  }
}
