@import './variables';

/* Set content for font-families */
$font-families: (
  'abril-fatface': 'Abril Fatface',
  'alegreya': 'Alegreya',
  'alex-brush': 'Alex Brush',
  'alfa-slab-one': 'Alfa Slab One',
  'andada': 'Andada',
  'arial': 'Arial',
  'arya': 'Arya',
  'asap': 'Asap',
  'baloo-2': 'Baloo 2',
  'bebas-neue': 'Bebas Neue',
  'calistoga': 'Calistoga',
  'courier-new': 'Courier New',
  'exo-2': 'Exo 2',
  'goudy-bookletter-1911': 'Goudy Bookletter 1911',
  'great-vibes': 'Great Vibes',
  'helvetica': 'Helvetica',
  'hind': 'Hind',
  'inter': 'Inter',
  'lato': 'Lato',
  'libre-barcode-128': 'Libre Barcode 128',
  'm-plus-1-p': 'M PLUS 1p',
  'montserrat': 'Montserrat',
  'mukta': 'Mukta',
  'open-sans': 'Open Sans',
  'open-sans-condensed': 'Open Sans Condensed',
  'oswald': 'Oswald',
  'pacifico': 'Pacifico',
  'press-start-2-p': 'Press Start 2P',
  'quicksand': 'Quicksand',
  'raleway': 'Raleway',
  'roboto': 'Roboto',
  'slackey': 'Slackey',
  'smokum': 'Smokum',
  'stalemate': 'Stalemate',
  'ubuntu': 'Ubuntu',
  'ubuntu-condensed': 'Ubuntu Condensed',
  'ubuntu-mono': 'Ubuntu Mono',
  'varela-round': 'Varela Round',
  'comic-sans-ms': 'Comic Sans MS',
  'georgia': 'Georgia',
  'lucida-sans-unicode': 'Lucida Sans Unicode',
);

@each $font, $font-name in $font-families {
  .ql-font-#{$font},
  .ql-font span[data-value='#{$font}']::before {
    font-family: #{$font-name};
  }
}

/* Set content for sizes */
$font-sizes: (8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72);

@each $size in $font-sizes {
  .ql-size-#{$size} {
    font-size: #{$size}px !important;
  }
}

div.my-2.break-words.default-font-size {
  p {
    font-size: 22px;
  }
}

div.my-2.break-words.default-header-size {
  h1,h2,h3,h4,h5,h6 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 0px;
  }
  p {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 0px;
  }
}

.ql-container {
  font-size: 22px;
}

.ql-align {
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-left {
    text-align: left;
  }
  &-justify {
    text-align: justify;
  }
}

.quill {
  &-white {
    background-color: white;
    color: #4a4a4a;
  }
  &-black {
    background-color: #4a4a4a;
    color: white;
    .ql-container.ql-snow {
      .ql-editor.ql-blank {
        &::before {
          color: white;
        }
      }
    }
  }
}

.ql-formats {
  .ant-switch.text-field-switch.ant-switch-checked {
    margin-left: 10px;
    font-size: 14px;
    .ant-switch-inner {
      margin: 0 25px 0 3px;
    }
  }
  .ant-switch-checked {
    background-color: #031f48 !important;
  }
}

#emoji-palette {
  border: none;
  @include mobile {
    top: 0 !important;
    left: 0 !important;
  }
}

.ap {
  border: none;
  background-color: unset;
}

.ql-emojiblot {
  vertical-align: baseline;
}
