.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: transparent !important;
}

.avatar-upload-btn {
  display: block;
  width: 100%;

  .ant-upload {
    width: 100%;
    .ant-btn {
      background-color: #f1f3f555;
      opacity: 0.75;
    }
  }
}

.custom-radio {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: stretch;
  padding: 0.25rem !important;
  border: 1px solid #eaf0ff;
  border-radius: 0.25rem;
  background-color: #fff;

  .ant-radio-button-wrapper {
    font-size: 0.8rem;
    opacity: 0.6;
    flex-grow: 1;
    text-align: center;
    border-radius: 0.25rem !important;
    border-color: transparent !important;
    border-width: 1px !important;
    transition: all 0.3s ease-in-out;

    &.ant-radio-button-wrapper-checked {
      opacity: 1;
      color: #0f61d9 !important;
      background: #eaf0ff !important;
      border-color: #0f61d95e !important;
      transition: all 0.5s ease-in-out;
    }
  }

  &.justify-center {
    justify-content: center;

    .ant-radio-button-wrapper {
      flex-grow: 0;
    }
  }
}

div[id^='font-picker'] {
  width: 100% !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  .dropdown-button {
    background: white !important;
    border: 1px solid #e2e8f0 !important;
    border-radius: 6px !important;
    &:hover {
      background: white !important;
    }
  }
  .dropdown-icon {
    display: none !important;
  }
  ul {
    border-radius: 6px !important;
    background: rgb(241, 241, 241) !important;
  }
}

.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  text-align: center;
  padding: 15px;
  font-size: 4vw;
  border: 1px solid transparent;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  font-weight: 700;

  .time-block {
    display: flex;
    flex-direction: column;
    line-height: 1;

    span {
      &:first-of-type {
        padding: 5px 8px;
        border: 1px solid transparent;
      }
    }
  }

  .time-description {
    display: none;
    font-size: 1vw;
  }

  .time-separator {
    position: relative;
    height: 100%;
    top: 0;
    font-size: 80%;
    transform: translateY(-5%);
  }

  &.show-description {
    .time-description {
      display: block;
      margin-top: 5px;
    }

    .time-separator {
      font-size: 90%;
      transform: translateY(-15%);
    }
  }

  &.curved {
    border-radius: 6px;

    .time-block {
      span {
        &:first-of-type {
          border-radius: 6px;
        }
      }
    }
  }

  &.pointed {
    border-radius: 0;

    .time-block {
      span {
        &:first-of-type {
          border-radius: 0;
        }
      }
    }
  }

  &.modern {
    .time-block {
      span {
        &:first-of-type {
          /* text-shadow: 0px 3px 6px #0000005C; */
          text-decoration: line-through;
        }
      }
    }
  }

  &.vintage {
    font-family: 'PT Mono', monospace;
    font-weight: 700;

    .time-block {
      span {
        &:first-of-type {
          text-shadow: 0px 3px 6px #0000005c;
        }
      }
    }
  }

  &.classic {
    .time-separator {
      padding: 0 3px;
    }
  }

  &.preview {
    font-size: 1.7vw;

    .time-description {
      font-size: 0.7vw;
    }

    @media screen and (max-width: 992px) {
      font-size: 3vw;

      .time-description {
        font-size: 1vw;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 6vw;

      .time-description {
        font-size: 2vw;
      }
    }
  }

  @media screen and (max-width: 992px) {
    font-size: 5vw;

    .time-description {
      font-size: 2vw;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 9vw;

    .time-description {
      font-size: 3vw;
    }
  }
}

.datepicker-dropdown {
  z-index: 32422 !important;
}

.custom-multiple-select {
  .ant-tag-close-icon {
    color: inherit !important;
  }
}

.ant-input {
  &.flex-mode {
    width: 100%;

    @media (min-width: 768px) {
      width: 49%;
    }
  }
}

.video-component-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  border-radius: 6px;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-live-chat {
  height: 100%;
  width: 100%;
}

.spacer-component {
  display: block;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;

  &::before {
    content: 'Empty Space';
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    color: #fff;
    text-shadow: 1px 1px 5px #000;
  }
}

.pointy .ant-collapse {
  border-radius: 0px;
}

.curve .ant-collapse {
  border-radius: 4px;
}


.ocp-checkout-preview.popup,.ocp-checkout-preview.inline  {
  position: relative;
  display: inline-block;
}

.ocp-checkout-preview.popup:hover:before {
  content: '';
  display: block;
  width: 320px; 
  height: 400px; 
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url('../../../../../../assets/images/pop-ocp.png');
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10000;
}

.ocp-checkout-preview.inline:hover:before {
  content: '';
  display: block;
  width: 320px; 
  height: 400px; 
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url('../../../../../../assets/images/overlay-ocp.png');
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10000;
}

